import './App.css';
import backgroundVideo from "./Gradient Loop.mp4";
import logo from "./logo.png";
import linkImage from "./x-logo.png";
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function MyVerticallyCenteredModal(props) {
  const [walletID, setWalletID] = React.useState('');
  const [error, setError] = React.useState('');
  const walletIDRef = React.useRef(null);

  React.useEffect(() => {
    if (props.show) {
      // Focus on the walletID field when the modal is shown
      walletIDRef.current.focus();
    }
  }, [props.show]);

  const handleWalletIDChange = (e) => {
    const value = e.target.value;
    setWalletID(value);
    if (!value.trim()) {
      setError('Wallet ID is required');
    } else {
      setError('');
    }
  }

  const handleSubmit = () => {
    if (!walletID.trim()) {
      setError('Wallet ID is required');
    } else if (!walletID.startsWith('0x') || walletID.length !== 42) {
      setError('Wallet ID must start with "0x" and be 42 characters in length');
    } else {
      setError('');

      const alias = document.getElementById('alias').value;
      const email = document.getElementById('emailID').value;

      fetch('https://landing-page-backend-theta.vercel.app/submitForm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ walletID, alias, email }),
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);
          // Show success toast
          toast.success('Form submitted successfully!', {
            position: toast.POSITION.TOP_CENTER,
          });
        })
        .catch(error => {
          console.error(error);
          // Show error toast
          toast.error('Form submission failed. Please try again.', {
            position: toast.POSITION.TOP_CENTER,
          });
        });

      props.onHide();
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Waitlist Form
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="walletID">
            <Form.Label>Wallet ID</Form.Label>
            <span className="required-asterisk" style={{ color: 'red', marginLeft: '4px' }}>*</span>
            <Form.Control
              required
              type="text"
              placeholder="Wallet Address"
              autoFocus
              value={walletID}
              onChange={handleWalletIDChange}
              ref={walletIDRef}
            />
            {error && <div className="error">{error}</div>}
          </Form.Group>
          <Form.Group className="mb-3" controlId="alias">
            <Form.Label>Alias</Form.Label>
            <Form.Control
              type="text"
              placeholder="John Doe"
              autoFocus
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="emailID">
            <Form.Label>Email ID</Form.Label>
            <Form.Control
              type="email"
              placeholder="name@example.com"
              autoFocus
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal>
  );
}

function App() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <div className="app-container">
      <ToastContainer />
      <video autoPlay loop playsInline muted id="video" /*style={videoStyle}*/>
        <source src={backgroundVideo} type="video/mp4" />
      </video>
      <div className="centered-content">
        <img src={logo} alt="Logo" className="logo" />
        <div className="text-container">
          <h1 className="main-heading">INTAGLIO FINANCE</h1>
          <p className="sub-heading">Your Definitive Edge</p>
          <div className="loading-bar">
            <div className="fill" style={{ width: "40%", background: "#FF6600" }}></div>
            <div className="empty" style={{ width: "60%", background: "#F9F9F9" }}></div>
          </div>
          <button id="joinWaitlistButton" className="waitlist-button" onClick={() => setModalShow(true)}>Join Waitlist</button>
          <MyVerticallyCenteredModal
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        </div>
        <a href="https://twitter.com/intagliofinance" target="_blank" rel="noopener noreferrer">
          <img src={linkImage} alt="Link" className="link-image" />
        </a>
      </div>
    </div>
  );
}

export default App;
